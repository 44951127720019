let mouseX = -1
let mouseY = -1

const doc = document.querySelector("body")
doc.addEventListener('mousemove', e => {
    mouseX = e.clientX
    mouseY = e.clientY
})

const scene = new THREE.Scene();

const ratio = window.innerWidth / window.innerHeight;
const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
renderer.setClearColor(0x000000, 0); // the default



const camera = new THREE.PerspectiveCamera(100, ratio, 0.01, 1500);
camera.position.z = 1100;

document.getElementById("placeholder").appendChild(renderer.domElement);

// Resize and update camera




// --- Main part, load and parse SVG
// ---------------------------------

const svgMarkup = `<svg class="absolute hidden md:h-[300px] md:w-[300px]" width="100%" height="100%"
viewBox="0 0 977 1414" version="1.1" xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
xmlns:serif="http://www.serif.com/"
style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
<g id="Artboard1" transform="matrix(1,0,0,1,-1265.56,-536.35)">
    <g clip-path="url(#_clip1)">
        <g transform="matrix(4.16667,0,0,4.16667,0.562439,0.349996)">
            <path
                d="M440.344,151.142C440.344,138.761 430.307,128.724 417.926,128.724C405.544,128.724 395.507,138.761 395.507,151.142C395.507,163.524 405.544,173.561 417.926,173.561C430.307,173.561 440.344,163.524 440.344,151.142Z"
                style="fill:rgb(0,174,239);fill-rule:nonzero;" />
            <path
                d="M319.636,400.581C319.636,396.191 316.076,392.631 311.685,392.631C307.295,392.631 303.735,396.191 303.735,400.581C303.735,404.972 307.295,408.532 311.685,408.532C316.076,408.532 319.636,404.972 319.636,400.581Z"
                style="fill:rgb(0,174,239);fill-rule:nonzero;" />
            <rect x="304.065" y="413.724" width="15.241" height="54.276"
                style="fill:rgb(0,174,239);fill-rule:nonzero;" />
            <path
                d="M352.527,425.238L345.401,425.238L345.401,453.636C345.401,455.39 346.388,456.268 348.362,456.268L352.527,456.268L352.527,468L343.975,468C334.765,468 330.16,463.943 330.16,455.829L330.16,425.238L324.458,425.238L324.458,413.724L330.16,413.724L330.16,403.638L345.401,403.638L345.401,413.724L352.527,413.724L352.527,425.238Z"
                style="fill:rgb(65,64,66);fill-rule:nonzero;" />
            <path
                d="M372.045,436.093L382.899,436.093L382.899,427.321C382.899,426.371 382.68,425.696 382.241,425.292C381.802,424.891 381.072,424.689 380.049,424.689L375.005,424.689C373.908,424.689 373.141,424.891 372.703,425.292C372.264,425.696 372.045,426.371 372.045,427.321L372.045,436.093ZM371.278,413.724L383.668,413.724C392.949,413.724 397.593,417.781 397.593,425.896L397.593,445.083L372.045,445.083L372.045,454.403C372.045,455.354 372.264,456.031 372.703,456.432C373.141,456.834 373.908,457.035 375.005,457.035L379.939,457.035C380.961,457.035 381.694,456.815 382.132,456.377C382.571,455.938 382.79,455.209 382.79,454.185L382.79,450.565L397.482,450.565L397.482,455.829C397.482,463.943 392.84,468 383.557,468L371.278,468C362.068,468 357.462,463.943 357.462,455.829L357.462,425.896C357.462,417.781 362.068,413.724 371.278,413.724Z"
                style="fill:rgb(65,64,66);fill-rule:nonzero;" />
            <path
                d="M443.313,433.241L428.4,433.241L428.4,427.979C428.4,426.152 427.413,425.238 425.44,425.238L422.59,425.238C420.616,425.238 419.628,426.152 419.628,427.979L419.628,453.746C419.628,455.574 420.616,456.487 422.59,456.487L425.44,456.487C427.413,456.487 428.4,455.574 428.4,453.746L428.4,448.482L443.313,448.482L443.313,455.829C443.313,463.943 438.671,468 429.388,468L418.203,468C408.919,468 404.278,463.943 404.278,455.829L404.278,425.896C404.278,417.781 408.919,413.724 418.203,413.724L429.388,413.724C438.671,413.724 443.313,417.781 443.313,425.896L443.313,433.241Z"
                style="fill:rgb(65,64,66);fill-rule:nonzero;" />
            <path
                d="M474.341,413.724L477.083,413.724C485.636,413.724 489.912,417.781 489.912,425.896L489.912,468L474.56,468L474.56,427.979C474.56,426.152 473.574,425.238 471.6,425.238L468.968,425.238C466.847,425.238 465.788,426.407 465.788,428.746L465.788,468L450.547,468L450.547,394.647L465.788,394.647L465.788,419.097C466.885,415.517 469.736,413.724 474.341,413.724Z"
                style="fill:rgb(65,64,66);fill-rule:nonzero;" />
            <path
                d="M523.133,454.294L523.133,427.43C523.133,425.604 522.146,424.689 520.172,424.689L516.116,424.689C514.142,424.689 513.156,425.604 513.156,427.43L513.156,454.294C513.156,456.121 514.142,457.035 516.116,457.035L520.172,457.035C522.146,457.035 523.133,456.121 523.133,454.294ZM511.621,413.724L524.668,413.724C529.494,413.724 532.946,414.767 535.031,416.85C537.113,418.933 538.155,421.948 538.155,425.896L538.155,455.829C538.155,459.775 537.113,462.791 535.031,464.875C532.946,466.958 529.494,468 524.668,468L511.621,468C506.797,468 503.343,466.958 501.258,464.875C499.176,462.791 498.134,459.775 498.134,455.829L498.134,425.896C498.134,421.948 499.176,418.933 501.258,416.85C503.343,414.767 506.797,413.724 511.621,413.724Z"
                style="fill:rgb(140,198,63);fill-rule:nonzero;" />
            <path
                d="M461.83,244.371L440.7,244.371L440.7,328.579C440.7,333.782 443.625,336.384 449.478,336.384L461.83,336.384L461.83,371.171L436.47,371.171C409.16,371.171 395.507,359.142 395.507,335.083L395.507,244.371L378.598,244.371L378.598,210.234L395.507,210.234L395.507,180.322L440.7,180.322L440.7,210.234L461.83,210.234L461.83,244.371Z"
                style="fill:rgb(65,64,66);fill-rule:nonzero;" />
            <path
                d="M470.956,337.641C505.679,338.387 505.679,338.387 505.679,324.542L505.679,257.052C505.224,242.979 506.525,244.371 470.956,244.371L470.956,210.559L498.28,210.559C512.547,210.559 522.754,213.643 528.918,219.801C535.076,225.96 538.155,234.875 538.155,246.548L538.155,335.051C538.155,346.719 535.076,355.635 528.918,361.798C522.754,367.956 512.547,371.035 498.28,371.035L470.956,371.171L470.956,337.641Z"
                style="fill:rgb(140,198,63);fill-rule:nonzero;" />
            <path
                d="M390.25,352.609L353.346,313.983L352.894,333.822L352.802,337.886C336.214,337.727 336.212,335.365 336.212,324.542L336.212,257.052C336.667,242.979 335.367,244.371 370.935,244.371L370.935,210.559L343.612,210.559C329.345,210.559 319.137,213.643 312.974,219.801C306.816,225.96 303.737,234.875 303.737,246.548L303.737,335.051C303.737,346.719 306.816,355.635 312.974,361.798C319.137,367.956 329.345,371.035 343.612,371.035L352.047,371.077L351.621,389.766L390.25,352.609Z"
                style="fill:rgb(140,198,63);fill-rule:nonzero;" />
        </g>
    </g>
</g>
</svg>`

const loader = new THREE.SVGLoader();
const data = loader.parse(svgMarkup);
const paths = data.paths;
const svgGroup = new THREE.Group();
svgGroup.scale.y *= -1;

for (let i = 0; i < paths.length; i++) {

    const path = paths[i];

    const material = new THREE.MeshBasicMaterial({
        color: path.color,
        side: THREE.DoubleSide,
    });

    const shapes = THREE.SVGLoader.createShapes(path);

    for (let j = 0; j < shapes.length; j++) {

        const shape = shapes[j];
        // const geometry = new THREE.ShapeGeometry( shape );
        const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: 50,
            bevelEnabled: false
        });
        const mesh = new THREE.Mesh(geometry, material);
        svgGroup.add(mesh);

    }

}
const box = new THREE.Box3().setFromObject(svgGroup);
const size = new THREE.Vector3();
box.getSize(size);
const yOffset = size.y / -2;
const xOffset = size.x / -2;
// // Offset all of group's elements, to center them
svgGroup.children.forEach(item => {
    item.position.x = xOffset;
    item.position.y = yOffset;
});

scene.add(svgGroup);


// --- Animation loop
// ------------------

function animate() {
    renderer.render(scene, camera);
    if (mouseX !== -1 || mouseY !== -1)  {
        svgGroup.rotation.x = -size.x / 1800 + mouseY/size.y
        svgGroup.rotation.y = -size.y / 1800 + mouseX/size.x
    }
    requestAnimationFrame(animate);
}

animate();